import React, { useState, useEffect } from "react";
import { onSnapshot, collection, query, where } from "@firebase/firestore";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { db } from "../../firebase/firebase";
import "./PickGraph.css";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const PickGraph = () => {
  const [graphData, setGraphData] = useState({
    labels: Array.from({ length: 24 }, (_, i) => `${i}:00`),
    datasets: [
      {
        label: 'Total Value of Picks per Hour',
        data: [],
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
      },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      const picksCollection = collection(db, "picks");

      const today = new Date();
      const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      const endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);

      const picksQuery = query(
        picksCollection,
        where("timestamp", ">=", startOfDay),
        where("timestamp", "<=", endOfDay)
      );

      onSnapshot(picksQuery, (querySnapshot) => {
        const hourlyTotals = new Array(24).fill(0);
        querySnapshot.forEach((doc) => {
          const pickData = doc.data();
          const hour = pickData.timestamp.toDate().getHours();
          hourlyTotals[hour] += parseFloat(pickData.pickValue || 0);
        });

        setGraphData({
          ...graphData,
          datasets: [{ ...graphData.datasets[0], data: hourlyTotals }],
        });
      });
    };

    fetchData();
  }, []);

  return (
    <div className="pick-graph">
      <h2>Picks Per Hour (Total Value)</h2>
      <Bar data={graphData} />
    </div>
  );
};

export default PickGraph;
