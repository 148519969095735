import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
} from "@firebase/firestore";
import { db } from "../../firebase/firebase";
import "./Placement.css";

function Placements() {
  const [usersPicks, setUsersPicks] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date()); // State for selected date

  useEffect(() => {
    fetchData(selectedDate); // Fetch data initially with the current selected date
  }, [selectedDate]); // Trigger fetchData when selectedDate changes

  const fetchData = async (selectedDate) => {
    const usersCollection = collection(db, "users");
    const usersSnapshot = await getDocs(usersCollection);
    const userPicksData = {};

    for (const userDoc of usersSnapshot.docs) {
      const userEmail = userDoc.id;
      const userRef = doc(db, "users", userEmail);

      const userDocData = (await getDoc(userRef)).data();
      const displayName = userDocData.displayname || "Unknown";

      userPicksData[displayName] = 0;

      // Modify the query to filter by the selected date
      const userPicksCollection = collection(usersCollection, userEmail, "picks");
      const startOfDay = new Date(selectedDate.setHours(0, 0, 0, 0)); // Set the start of the selected date
      const endOfDay = new Date(selectedDate.setHours(23, 59, 59, 999)); // Set the end of the selected date
      const picksQuery = query(
        userPicksCollection,
        where("timestamp", ">=", startOfDay),
        where("timestamp", "<=", endOfDay)
      );

      const userPicksSnapshot = await getDocs(picksQuery);

      userPicksSnapshot.forEach((pickDoc) => {
        const pickData = pickDoc.data();
        const pickValue = parseFloat(pickData.pickValue);
        userPicksData[displayName] += pickValue;
      });
    }

    const userPicksArray = Object.entries(userPicksData).map(
      ([displayName, total]) => ({
        displayName,
        total,
      })
    );

    // Sort user picks by total (highest to lowest)
    userPicksArray.sort((a, b) => b.total - a.total);

    setUsersPicks(userPicksArray);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date); // Update selected date when the user picks a new date
  };

  return (
    <div className="placements">
      <h1>Placements</h1>
      <div className="date-picker">
        <input
          type="date"
          value={selectedDate.toISOString().split("T")[0]}
          onChange={(e) => handleDateChange(new Date(e.target.value))}
        />
      </div>
      <div className="user-picks">
        {usersPicks.map((userPick) => (
          <div key={userPick.displayName} className="user-box">
            <div className="user-info">
              <span className="display-name">{userPick.displayName}</span>
              <span className="total-picks">{userPick.total} picks</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Placements;
