import React, { useState, useEffect } from 'react';
import { FaUser, FaLock } from 'react-icons/fa';
import { app, db } from "../../firebase/firebase";
import { onSnapshot, collection, setDoc, doc } from "@firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import './LoginForm.css';
import PickTracker from '../PickTracker/PickTracker';
import PickGraph from '../PickGraph/PickGraph';
import Menu from '..//Menu/Menu';
import { updateProfile } from "firebase/auth";


const LoginForm = () => {
    const [isRegistering, setIsRegistering] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [newPick, setNewPick] = useState('');
    const [displayname, setDisplayName] = useState('');

    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user);
                setDisplayName(user.displayname || '');
            } else {
                setCurrentUser(null);
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const handleRegisterSubmit = async (event) => {
        event.preventDefault();
        const username = event.target.elements[0].value;
        const password = event.target.elements[1].value;
        const displayname = event.target.elements[2].value; // Extract the display name from the input field
    
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, username, password);
            const user = userCredential.user;
    
            // Update user's display name
            await updateProfile(user, { displayname });
    
            // Automatically save display name and email to Firestore
            await setDoc(doc(db, 'users', user.email), {
                displayname, // Save display name
                email: user.email, // Save email
            });
    
            setIsRegistering(false);
        } catch (error) {
            console.error("Error:", error.message);
        }
    };
    
    

    const handleLoginSubmit = async (event) => {
        event.preventDefault();
        const username = event.target.elements[0].value;
        const password = event.target.elements[1].value;

        try {
            await signInWithEmailAndPassword(auth, username, password);
            console.log("Success");
        } catch (error) {
            console.error("Error:", error.message);
        }
    };

    useEffect(() => {
        onSnapshot(collection(db, "users"), (snapshot) =>
            console.log(snapshot.docs.map((doc) => doc.data()))
        );
    }, []);

    return (
        <div className='wrapper'>
            {currentUser ? (
                <>
                    <Menu />
                    <PickTracker />
                </>
            ) : isRegistering ? (
                <form action="" onSubmit={handleRegisterSubmit}>
    <h1>Register</h1>
    <div className="input-box">
        <input type="text" placeholder='Username' required />
        <FaUser className='icon' />
    </div>
    <div className="input-box">
        <input type="password" placeholder='Password' required />
        <FaLock className='icon' />
    </div>
    <div className="input-box">
        <input type="text" placeholder='Display Name' required /> {/* Add the display name input field */}
        <FaUser className='icon' />
    </div>
    <button type="submit">Register</button>
    <div className="register-link">
        <p>Already have an account? <a href="#" onClick={(e) => { e.preventDefault(); setIsRegistering(false); }}>Login</a></p>
    </div>
</form>
            ) : (
                <form action="" onSubmit={handleLoginSubmit}>
                    <h1>Login</h1>
                    <div className="input-box">
                        <input type="text" placeholder='Username' required />
                        <FaUser className='icon' />
                    </div>
                    <div className="input-box">
                        <input type="password" placeholder='Password' required />
                        <FaLock className='icon' />
                    </div>
                    <div className="remember-forgot">
                        <label><input type="checkbox" />Remember me</label>
                        <a href="#">Forgot password?</a>
                    </div>
                    <button type="submit">Login</button>
                    <div className="register-link">
                        <p>Don't have an account? <a href="#" onClick={(e) => { e.preventDefault(); setIsRegistering(true); }}>Register</a></p>
                    </div>
                </form>
            )}
        </div>
    );
};

export default LoginForm;
