import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import LoginForm from '../src/components/LoginForm/LoginForm';
import Menu from '../src/components/Menu/Menu';
import PickTracker from '../src/components/PickTracker/PickTracker'; // Import your components here
import Placements from '../src/components/Placement/Placement';

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [showPickTracker, setShowPickTracker] = useState(false);
  const [showPlacements, setShowPlacements] = useState(false);

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth]);

  const handlePickTrackerClick = () => {
    setShowPickTracker(true);
    setShowPlacements(false);
  };

  const handlePlacementsClick = () => {
    setShowPickTracker(false);
    setShowPlacements(true);
  };

  const handleLogoutClick = () => {
    signOut(auth)
      .then(() => {
        // User signed out
        setCurrentUser(null);
        setShowPickTracker(false);
        setShowPlacements(false);
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  return (
    <div>
      {currentUser ? (
        <Menu
          onPickTrackerClick={handlePickTrackerClick}
          onPlacementsClick={handlePlacementsClick}
          onLogoutClick={handleLogoutClick}
        />
      ) : (
        <LoginForm />
      )}

      {showPickTracker && <PickTracker />}
      {showPlacements && <Placements />}
      {/* Render other components based on boolean flags */}
    </div>
  );
}

export default App;
