import React from 'react';
import './Menu.css';

function Menu({ onPickTrackerClick, onPlacementsClick, onLogoutClick }) {
  return (
    <div className="menu-wrapper">
      <ul>
        <li className="menu-item">
          <button className="menu-button" onClick={onPickTrackerClick}>PickTracker</button>
        </li>
        <li className="menu-item">
          <button className="menu-button" onClick={onPlacementsClick}>Placements</button>
        </li>
        {/* Add more menu items for your components */}
      </ul>
      <button className="menu-button logout-button" onClick={onLogoutClick}>Logout</button>
    </div>
  );
}

export default Menu;
