import React, { useState, useEffect } from "react";
import {
    onSnapshot,
    collection,
    doc,
    addDoc,
    deleteDoc,
    serverTimestamp,
    where,
    query,
    getDoc,
    setDoc,
    Timestamp,
  } from '@firebase/firestore';

import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { app, db } from "../../firebase/firebase";
import { getAuth } from 'firebase/auth';
import "./PickTracker.css";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
ChartJS.defaults.color = '#FFFFFF';

const PickTracker = ({ onLogout }) => {
  const [userPicks, setUserPicks] = useState([]);
  const [newPick, setNewPick] = useState('');
  const [totalPicks, setTotalPicks] = useState(0);
  const [picksPerHour, setPicksPerHour] = useState(0);

  // Initialize selectedDate with the current day
  const [selectedDate, setSelectedDate] = useState(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to start of the day
    return today;
  });

  const auth = getAuth(app);

  const [graphData, setGraphData] = useState({
    labels: Array.from({ length: 18 }, (_, i) => `${i + 4}:00`),
    datasets: [
      {
        label: 'Total Value of Picks per Hour',
        data: [],
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
      },
    ],
  });

  useEffect(() => {
    fetchData();
  }, [auth, selectedDate]);

  const fetchData = async () => {
    const userId = auth.currentUser ? auth.currentUser.email : '';
    const userPicksCollection = collection(db, 'users', userId, 'picks');

    const startOfDay = new Date(selectedDate);
    const endOfDay = new Date(selectedDate);
    endOfDay.setHours(23, 59, 59, 999);

    const picksQuery = query(
      userPicksCollection,
      where('timestamp', '>=', Timestamp.fromDate(startOfDay)),
      where('timestamp', '<=', Timestamp.fromDate(endOfDay))
    );

    onSnapshot(picksQuery, (querySnapshot) => {
      const picksData = [];
      querySnapshot.forEach((doc) => {
        picksData.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      const sortedPicks = picksData.sort((a, b) => a.timestamp.seconds - b.timestamp.seconds);
      const total = sortedPicks.reduce(
        (acc, pick) => acc + parseFloat(pick.pickValue),
        0
      );

      setTotalPicks(total);

      if (sortedPicks.length > 0) {
        const firstPickTimestamp = sortedPicks[0].timestamp.toDate();
        const now = new Date();
        const hoursSinceFirstPick = (now - firstPickTimestamp) / (1000 * 60 * 60);
        const calculatedPicksPerHour = total / hoursSinceFirstPick;
        setPicksPerHour(calculatedPicksPerHour);
      } else {
        setPicksPerHour(0);
      }

      setUserPicks(sortedPicks);

      const hourlyTotals = new Array(18).fill(0);
      sortedPicks.forEach((pick) => {
        const hour = pick.timestamp.toDate().getHours();
        if (hour >= 4 && hour <= 21) {
          hourlyTotals[hour - 4] += parseFloat(pick.pickValue);
        }
      });

      setGraphData({
        ...graphData,
        datasets: [{ ...graphData.datasets[0], data: hourlyTotals }],
      });
    });
  };

  const handlePickChange = (event) => {
    setNewPick(event.target.value);
  };

  const handleComplete = async () => {
    if (newPick.trim() === '') {
      return;
    }

    const userId = auth.currentUser ? auth.currentUser.email : '';
    const userPicksCollection = collection(db, 'users', userId, 'picks');

    try {
      await addDoc(userPicksCollection, {
        pickValue: parseFloat(newPick),
        timestamp: serverTimestamp(),
      });

      const userRef = doc(db, 'users', userId);
      const userDocSnapshot = await getDoc(userRef);

      if (!userDocSnapshot.exists()) {
        await setDoc(userRef, {
          email: auth.currentUser.email,
          displayName: auth.currentUser.displayName || '',
        });
      }

      setNewPick('');
      fetchData(); // Refresh data after adding a new pick
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleDelete = async (pickId) => {
    const userId = auth.currentUser ? auth.currentUser.email : '';
    const pickDocRef = doc(db, 'users', userId, 'picks', pickId);

    try {
      await deleteDoc(pickDocRef);
      fetchData(); // Refresh data after deleting a pick
    } catch (error) {
      console.error('Error deleting pick:', error.message);
    }
  };

  const handleDateChange = (e) => {
    setSelectedDate(new Date(e.target.value));
  };

  return (
    <div className='pick-tracker-box'>
      <div className='pick-tracker'>
        <h1>Pick Tracker</h1>
        <div className='date-picker'>
          <input
            type='date'
            value={selectedDate.toISOString().split("T")[0]}
            onChange={handleDateChange}
          />
        </div>
        <div className='total-picks'>Total Picks: {totalPicks}</div>
        <div className='picks-per-hour'>
          Picks Per Hour: {picksPerHour.toFixed(2)}
        </div>
        <ul className='pick-list' style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {userPicks.slice().reverse().map((pick) => (
            <li key={pick.id} className='pick-item'>
              <div className='pick-value-box'>
                <span className='pick-value'>{pick.pickValue}</span>
              </div>
              <div className='pick-timestamp-box'>
                <span className='pick-timestamp'>
                  {pick.timestamp ? new Date(pick.timestamp.toDate()).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  }) : 'No timestamp'}
                </span>
              </div>
              <div className='delete-button-box'>
                <button className='delete-button' onClick={() => handleDelete(pick.id)}>
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
        <div className='pick-input-box'>
          <input
            type='text'
            placeholder='Enter your pick'
            value={newPick}
            onChange={handlePickChange}
          />
          <button onClick={handleComplete} className='complete-button'>
            Complete
          </button>
        </div>
        <div>
          <Bar data={graphData} />
        </div>
      </div>
    </div>
  );
};

export default PickTracker;
