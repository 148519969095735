// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDpj8708rtqCt-qL6R8mItiNPv0YpXh4bw",
  authDomain: "fir-aae23.firebaseapp.com",
  projectId: "picktracker-ae905",
  storageBucket: "fir-aae23.appspot.com",
  messagingSenderId: "962443006145",
  appId: "1:962443006145:web:dc2acf3f4a7e2cea294440",
  measurementId: "G-6FXQ30S95W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { app, db };